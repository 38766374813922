import Rellax from 'rellax'

export default class ParallaxComponent {
    trigger = '[data-hc-trigger="parallax"]'

    constructor() {
        if ($(this.trigger).length > 0) {
            const parallax = new Rellax(this.trigger)
        }
    }
}
