export default class DropdownAction {
    trigger = '[data-hc-trigger="dropdown"]'

    constructor() {
        const triggers = document.querySelectorAll(this.trigger)
        triggers.forEach((trigger) => {
            trigger.addEventListener('click', (e) => {
                const height = e.currentTarget.parentElement.offsetHeight
                let open = true
                if (!e.currentTarget.parentElement.classList.contains('dropdown--open')) {
                    open = false
                }
                this.closeAll()
                if (open === false) {
                    e.currentTarget.parentElement.classList.add('dropdown--open')
                }
                e.currentTarget.parentElement.querySelector('.dropdown__menu').style.top =
                    `${height}px`
            })
        })

        document.addEventListener('click', (e) => {
            if (
                e.target.closest(this.trigger) === null &&
                e.target.closest('.dropdown__menu') === null
            ) {
                this.closeAll()
            }
        })
    }

    closeAll() {
        document.querySelectorAll('.dropdown--open').forEach((dropdown) => {
            dropdown.classList.remove('dropdown--open')
        })
    }
}
