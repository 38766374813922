export default class Navbar {
    constructor() {
        Navbar.sticky()
        Navbar.burger()
        Navbar.langsSwitcher()
    }

    static sticky() {
        let last_scroll_top = 0

        $(window).on('load scroll', function () {
            const offset = 120 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scroll_top = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scroll_top >= last_scroll_top && scroll_top > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('.header--with-banner').length > 0) {
                full = true
            }

            last_scroll_top = scroll_top

            if (scroll_top > offset) {
                navbar.removeClass('header__navbar--ontop')
                navbar.addClass('header__navbar--onscroll')
                if (full === true) {
                    Navbar.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('header__navbar--onscroll')
                navbar.addClass('header__navbar--ontop')
                if (full === true) {
                    Navbar.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logo_banner
        let logo_classic

        logo_banner = IRISCollectionCustomer.imageLogo
        logo_classic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logo_banner)
        } else if (color === 'classic') {
            logo.attr('src', logo_classic)
        }
    }

    static langsSwitcher() {
        const current_lang = $('.tools__item__languages__current')
        const list_langs = $('.tools__item__languages__langs')

        //On init
        $(window).on('load', () => {
            window.setTimeout(() => {
                let current_language = $('html').attr('lang').replace(/-.*/gi, '')
                if (current_language === 'auto') {
                    current_language = 'fr'
                }

                $('.tools__item__languages__langs > a').each(function () {
                    if ($(this).text().toUpperCase() === current_language.toUpperCase()) {
                        $(this).hide()
                    }
                })

                $(current_lang).text(current_language)
            }, 500)
        })

        //Open submenu
        $(current_lang).on('click', () => {
            $(list_langs).toggleClass('tools__item__languages__langs--open')
        })

        //Select lang
        $(list_langs).on('click', () => {
            $(list_langs).toggleClass('tools__item__languages__langs--open')
        })

        //Change Navbar Language on click
        $('.tools__item__languages__langs > a').on('click', function () {
            $('.tools__item__languages__langs > a').show()
            $(this).hide()
            $(current_lang).text($(this).text())
        })
    }

    static burger() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        $(trigger).on('click', () => {
            $('body').toggleClass('menu-open')
        })

        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
        })
    }
}
